/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.form-field {
    width: 200px;
}

.mat-orange {
    background-color: rgb(203, 57, 4);
    color: white;
}

th {
    text-align: left;
    border: 1px solid white;
    padding-left: 2%;
    padding-top: 2%;
    padding-bottom: 2%;
}

tr:nth-child(odd) {
    background-color: rgb(203, 57, 4, 0.65);
}

td {
    border: 1px solid white;
    padding-left: 2%;
    padding-top: 2%;
    padding-bottom: 2%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}